<template>
  <v-container class="fill-height d-flex justify-center align-center">
    <v-card class="overflow-hidden rounded-lg pa-4">
      <v-form ref="form" v-model="valid" v-on:submit.prevent="login" :disabled="loading" lazy-validation>
        <v-card-title class="d-flex justify-center">
          <v-img alt="MAIA logo" contain src="@/assets/maia-logo.svg" max-width="100%" />
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            outlined
            :error="error !== ''"
            v-model="username"
            label="Username"
            id="username"
            type="text"
            required
            @input="error = ''"
          ></v-text-field>
          <v-text-field
            outlined
            v-model="password"
            label="Password"
            id="password"
            type="password"
            required
            :hide-details="!error"
            :error-messages="error ? [$t('errors.login.' + error)] : []"
            @input="error = ''"
          ></v-text-field>
          <v-checkbox
            v-if="privacyPolicy"
            id="accepted_privacy_policy"
            outlined
            v-model="accepted_privacy_policy"
            :rules="[(v) => !!v || $t('form.validation.accept_privacy_policy')]"
            required
          >
            <template v-slot:label>
              {{ $t("accept_privacy_policy") }} <v-btn text small @click="preview = true">Privacy Policy</v-btn>
            </template>
          </v-checkbox>
          <router-link to="/forgotpassword">Dimenticata la password?</router-link>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!valid" elevation="0" block type="submit" color="primary" :loading="loading">Login</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <file-preview v-if="privacyPolicy" v-model="preview" :src="privacyPolicy.link" title="aa" />
  </v-container>
</template>

<script>
import { onLogin } from "@/vue-apollo";
import { LOGIN } from "@/graphql/mutations";

import { AVAILABLE_PAGES } from "@/maia/utils";
import { ME } from "../graphql/queries";

import FilePreview from "@/components/base/FilePreview.vue";

import { nextTick } from "vue";

export default {
  name: "Login",

  components: {
    FilePreview,
  },

  data: () => ({
    username: "",
    password: "",
    loading: false,
    data: null,
    error: "",
    valid: true,
    privacyPolicy: null,
    accepted_privacy_policy: null,
    preview: false,
  }),

  apollo: {
    me: ME,
  },

  methods: {
    login() {
      this.loading = true;
      if (!this.valid) return;

      this.$apollo
        .mutate({
          mutation: LOGIN,
          variables: {
            username: this.username,
            password: this.password,
            accepted_privacy_policy: this.accepted_privacy_policy,
          },
        })
        .then(({ data: { login } }) => {
          if (login.user === null) {
            if (login.token)
              this.$router.push({
                name: "ForgotPassword",
                params: { username: this.username, token: login.token },
              });
            this.privacyPolicy = login.privacyPolicy;
            this.loading = false;
            nextTick(() => this.$refs.form.validate());
          } else
            onLogin(this.$apollo.provider.defaultClient, login.token).then(() =>
              this.$router.push("/" + this.$i18n.locale + AVAILABLE_PAGES[login.user.type][0].path)
            );
        })
        .catch((error) => {
          this.error = error.graphQLErrors[0].message;
          this.loading = false;
        });
    },
  },
};
</script>
